/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2022 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as bowser from 'bowser';
import * as Flow from '@flowjs/flow.js';

import { StatusConstant } from '../../constants/status.constant';
import { AuthConstant } from '../../constants/auth.constant';
import { DynamicSpots } from './spots-dynamicitems';
// import { GoogleAnalyticsServiceService } from 'app/google-analytics-service.service';

//// CONTROLLER ////
export class SpotGridController {
    /* PRIVATE : DATA */
    //Declare all private variables here
    private statusConstants: any = new StatusConstant();
    private authConstants: any = new AuthConstant();

    // Bindable
    public disableScroll: Boolean = false;
    public sessionData: any;
    public spotView: string | null = null;
    public dynamicItems: any;
    public SearchParams: any = {};
    public newGridLoad: Boolean = true;
    public spots: any[] = [];
    public spotThumbs: any[] = [];
    public spotStatuses: any = angular.copy(this.statusConstants.spots);
    public deliveriesStatuses: any = angular.copy(this.statusConstants.deliveries);
    public gettingAssetUuids: Boolean = false;
    public downloadSelections: any;
    public downloadUuids: any;
    public downloadInProgress: Boolean = false;
    public sortObj: any;
    public selectedSpots: any[] = [];
    public lastSelection: number = -1;
    public searching: Boolean = true;
    public onlyArchivesSelected: Boolean = true;
    public displayListView: Boolean = true;
    public fileToUpload: any = {};
    public spotDeletedCount: number = 0;
    public spotNotDeletedCount: number = 0;
    public hasProdVendor: Boolean = false;
    public spotSentCount: number = 0;
    public spotNotSentCount: number = 0;
    public bowser: any = bowser;
    public isFranchise: Boolean = false;
    public accountType: string;
    public isTapProvider: Boolean = true;
    public spotDownloadLink: string = '';
    public distributionMasterDownloadLink: string = '';
    public mezzanineDownloadLink: string = '';
    public qtDownloadLink: string = '';
    public vqcDownloadLink: string = '';
    public prodServicesAllowed: Boolean = true;
    public numItems: number = 0;
    public activeUploads: any = [];
    public importingSpots: Boolean = false;

    // Inject the necessary providers
    static $inject = [
        '$cookies',
        '$mdDialog',
        '$q',
        '$scope',
        '$state',
        '$stateParams',
        '$timeout',
        '$window',
        'AssetResourceFactory',
        'EndPointService',
        'loginService',
        'NotificationService',
        'socket',
        'SpotResourceFactory',
        'StatusService',
        'AuthenticationResourceFactory',
        'BulkUploadService',
        'GoogleAnalyticsServiceService'
    ];

    constructor(
        public $cookies: any,
        public $mdDialog: any,
        public $q: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public AssetResourceFactory: any,
        public EndPointService: any,
        private loginService: any,
        public NotificationService: any,
        public socket: any,
        public SpotResourceFactory: any,
        public StatusService: any,
        public AuthenticationResourceFactory: any,
        private BulkUploadService: any,
        public GoogleAnalyticsServiceService : any,
    ) {
        var vm = this;

        /* BINDABLE: DATA */
        vm.sessionData = loginService.getSessionData();
        this.deliveriesStatuses.COMPLETED.status = 'Delivered';
        this.downloadSelections = {
            originalSpot: false,
            distributionMaster: false,
            mezzanine: false,
            quicktimeProxy: false,
            qcReport: false,
            videoQC: false,
            deliveryHistoryReport: false,
        };
        this.downloadUuids = {
            originalSpot: false,
            distributionMaster: false,
            mezzanine: false,
            quicktimeProxy: false,
            qcReport: false,
            videoQC: false,
            deliveryHistoryReport: false,
        };
        this.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'DESC',
        };

        // Just to make unit testing easier
        this.isFranchise = this.sessionData.franchiseCustomer;
        this.accountType = this.sessionData.accountType.replace(/_/g, '');
        this.isTapProvider = this.sessionData.account.tapIngestFlag;

        angular
            .element(document.getElementsByClassName('inf-scroll-container')[0])
            .on('scroll wheel touchmove touchend', () => {
                if (document.getElementsByClassName('last-card')[0]) {
                    var containerBottomLoc = document
                        .getElementsByClassName('inf-scroll-container')[0]
                        .getBoundingClientRect().bottom;
                    var cardBottomLoc = document
                        .getElementsByClassName('last-card')[0]
                        .getBoundingClientRect().bottom;
                    if (cardBottomLoc < containerBottomLoc + 500) {
                        if (this.disableScroll) {
                            this.disableScroll = false;
                            this.loadMoreCards();
                        } else {
                            return;
                        }
                    }
                }
            });

        /* EVENTS */
        //Register any event listeners
        $scope.$on('$stateChangeSuccess', (event: any, toState: any, toParams: any) => {
            this.newGridLoad = true;
            // The below is only left in here for the grid view, but honestly, it should be migrated to use the dynamicItems property too
            // Also of note: grid view was decommed a while back and we may just be able to delete the code for it.
            this.SearchParams = toParams;
        });

        socket.on('Spot_Ingest_Progress', (ingestStatus: any) => {
            if (this.displayListView) {
                let currentItem: any = {};
                for (let i = 0; i < this.dynamicItems.getLength(); i++) {
                    currentItem = this.dynamicItems.getItemAtIndex(i, true);
                    // If the retrieval failed, we likely haven't retrieved that spot yet
                    if (!currentItem) {
                        return;
                    }

                    if (ingestStatus.spotId === currentItem.id) {
                        currentItem.status = ingestStatus.ingestStatus;
                        currentItem.currentProgress = ingestStatus.ingestPercent;
                        this.dynamicItems.setItemAtIndex(i, currentItem);
                    }
                }
            } else {
                for (let i = 0; i < this.spots.length; i++) {
                    if (ingestStatus.spotId === this.spots[i].id) {
                        this.spots[i].status = ingestStatus.ingestStatus;
                        this.spots[i].currentProgress = ingestStatus.ingestPercent;
                    }
                }
            }
        });
    }

    $onInit() {

        this.$timeout(() => {
            $('#spotListSearchIsci').focus();
            this.dynamicItems = new DynamicSpots(
                this.SearchParams,
                this.SpotResourceFactory,
                this.AssetResourceFactory,
                this.StatusService,
                this.loginService,
                this.$timeout,
                this.$scope,
                this.sessionData.accountType
            );
        });
        $('.sidebar').removeClass('hidden');
        this.$scope.home.showTgl = true;
        this.loadListView();
        this.hasProdVendor = this.loginService.getSessionData().account.productionServicesVendorId;
    }

    sortSpots(field: string) {
        if (this.sortObj.field === field) {
            this.sortObj.direction = this.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
            this.sortObj.field = field;
            this.sortObj.direction = 'ASC';
        }

        this.$state.go(
            'spotList',
            {
                sortField: this.sortObj.field,
                sortDirection: this.sortObj.direction,
            },
            { inherit: true }
        );
    }

    /* IMPLEMENTATION : BINDABLE */
    openDownloadMenu($mdMenu: any, ev: any, spotId: any) {
        // Reset the download selections and Uuids
        this.downloadSelections = {
            originalSpot: false,
            distributionMaster: false,
            mezzanine: false,
            quicktimeProxy: false,
            qcReport: false,
            videoQC: false,
            deliveryHistoryReport: false,
        };
        this.downloadUuids = {
            originalSpot: false,
            distributionMaster: false,
            mezzanine: false,
            quicktimeProxy: false,
            qcReport: false,
            videoQC: false,
            deliveryHistoryReport: true,
        };
        // Set the loading flag
        this.gettingAssetUuids = true;
        // Retrieve the assetMap
        this.AssetResourceFactory.getSpotAssetUuids(
            { id: spotId },
            {},
            (assetMap: any) => {
                var mapData = assetMap.data;
                this.downloadUuids.originalSpot =
                    mapData.ORIGINAL_CONTENT && mapData.ORIGINAL_CONTENT.length > 0
                        ? mapData.ORIGINAL_CONTENT[0]
                        : false;
                this.downloadUuids.distributionMaster =
                    mapData.DISTRIBUTION_MASTER &&
                        mapData.DISTRIBUTION_MASTER.length > 0
                        ? mapData.DISTRIBUTION_MASTER[0]
                        : false;
                this.downloadUuids.mezzanine =
                    mapData.MEZZANINE && mapData.MEZZANINE.length > 0
                        ? mapData.MEZZANINE[0]
                        : false;
                this.downloadUuids.quicktimeProxy =
                    mapData.QT_PROXY && mapData.QT_PROXY.length > 0
                        ? mapData.QT_PROXY[0]
                        : false;
                this.downloadUuids.qcReport =
                    mapData.QC_REPORT_DATA && mapData.QC_REPORT_DATA.length > 0
                        ? mapData.QC_REPORT_DATA[0]
                        : false;
                this.downloadUuids.videoQC =
                    mapData.BATON_REPORT && mapData.BATON_REPORT.length > 0
                        ? mapData.BATON_REPORT[0]
                        : false;
                this.gettingAssetUuids = false;
            },
            function failure(err: any) {
                console.log(err);
            }
        );
        // Go ahead and open the menu right away
        $mdMenu.open(ev);
    }

    createNewSpot() {
        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('invisible');
        this.$state.go('spotDetail', { id: '' }).then(() => {
            // Apply the animation ONLY when this transition happens
            this.$timeout(() => {
                angular
                    .element(document.querySelector('.sidebar') as HTMLElement)
                    .removeClass('invisible');
                angular
                    .element(document.querySelector('.detail-list-area .side-grid') as HTMLElement)
                    .addClass('slide-left-transition');
            }, 0);
        });
    }

    downloadAssets(spot: any) {
        this.NotificationService.showNotificationToast('Download(s) started.');

        // Build download links
        let endpoint = this.EndPointService.assetDownloadEndpoint + '/' + spot.id + '/';

        if (this.downloadSelections.originalSpot) {
            this.spotDownloadLink = this.downloadSelections.originalSpot
                ? endpoint +
                '?' +
                'assetUuid=' +
                this.downloadUuids.originalSpot +
                '&' +
                'baseFilename=' +
                spot.isci +
                '&' +
                'authorization=' +
                this.loginService.getJwt()
                : '';

            this.$window.open(this.spotDownloadLink);
        }
        if (this.downloadSelections.distributionMaster) {
            this.distributionMasterDownloadLink = this.downloadSelections.distributionMaster
                ? endpoint +
                this.downloadUuids.distributionMaster +
                '?' +
                'baseFilename=' +
                ('spot_' + spot.isci + '_distributionMaster').replace('__', '_') +
                '&' +
                'authorization=' +
                this.loginService.getJwt()
                : '';

            this.$window.open(this.distributionMasterDownloadLink);
        }
        if (this.downloadSelections.mezzanine) {
            this.mezzanineDownloadLink = this.downloadSelections.mezzanine
                ? endpoint +
                this.downloadUuids.mezzanine +
                '?' +
                'baseFilename=' +
                ('spot_' + spot.isci + '_mezzanine').replace('__', '_') +
                '&' +
                'authorization=' +
                this.loginService.getJwt()
                : '';

            this.$window.open(this.mezzanineDownloadLink);
        }
        if (this.downloadSelections.quicktimeProxy) {
            this.qtDownloadLink = this.downloadSelections.quicktimeProxy
                ? endpoint +
                this.downloadUuids.quicktimeProxy +
                '?' +
                'baseFilename=' +
                ('spot_' + spot.isci + '_proxy').replace('__', '_') +
                '&' +
                'authorization=' +
                this.loginService.getJwt()
                : '';

            this.$window.open(this.qtDownloadLink);
        }
        if (this.downloadSelections.videoQC) {
            this.vqcDownloadLink = this.downloadSelections.videoQC
                ? endpoint +
                this.downloadUuids.videoQC +
                '?' +
                'baseFilename=' +
                ('spot_' + spot.isci + '_QC-Report').replace('__', '_') +
                '&' +
                'authorization=' +
                this.loginService.getJwt()
                : '';

            this.$window.open(this.vqcDownloadLink);
        }

        if (this.downloadSelections.qcReport) {
            this._viewMetadataReport(spot.id);
        }
        if (this.downloadSelections.deliveryHistoryReport) {
            this._viewHistoryReport(spot);
        }
    }

    sortGrid(direction: string) {
        if (this.sortObj.direction !== direction) {
            this.sortObj.direction = direction;
            this.$state.go(
                'spotList',
                {
                    sortField: this.sortObj.field,
                    sortDirection: this.sortObj.direction,
                },
                { inherit: true }
            );
        }
    }

    selectSpot(spot: any, index: number, event: any) {
        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
            this.selectedSpots = [];
        }

        if (event.shiftKey && this.lastSelection > -1) {
            // Do multiselect
            var toIndex = Math.max(index, this.lastSelection);
            var fromIndex = Math.min(index, this.lastSelection);

            for (let i = fromIndex; i <= toIndex; i++) {
                if (this.selectedSpots.indexOf(i) < 0) {
                    this.selectedSpots.push(i);
                }
            }
        } else if (this.selectedSpots.indexOf(index) < 0) {
            this.selectedSpots.push(index);
        } else {
            this.selectedSpots.splice(this.selectedSpots.indexOf(index), 1);
        }

        this.onlyArchivesSelected = true;
        for (let spotIndex = 0; spotIndex < this.selectedSpots.length; spotIndex++) {
            if (
                (this.dynamicItems.getItemAtIndex(this.selectedSpots[spotIndex]) &&
                    this.dynamicItems.getItemAtIndex(this.selectedSpots[spotIndex])
                        .archived !== true) ||
                (this.spots &&
                    this.spots[this.selectedSpots[spotIndex]] &&
                    this.spots[this.selectedSpots[spotIndex]].archived !== true)
            ) {
                this.onlyArchivesSelected = false;
            }
        }

        this.prodServicesAllowed = true;
        for (let spotIndex = 0; spotIndex < this.selectedSpots.length; spotIndex++) {
            if (
                (this.dynamicItems.getItemAtIndex(this.selectedSpots[spotIndex]) &&
                    ['PENDING_MEDIA', 'PENDING_PROD_SVCS', 'UNSENT'].indexOf(
                        this.dynamicItems.getItemAtIndex(this.selectedSpots[spotIndex])
                            .status
                    ) < 0) ||
                (this.spots &&
                    this.spots[this.selectedSpots[spotIndex]] &&
                    ['PENDING_MEDIA', 'PENDING_PROD_SVCS', 'UNSENT'].indexOf(
                        this.spots[this.selectedSpots[spotIndex]].status
                    ) < 0)
            ) {
                this.prodServicesAllowed = false;
            }
        }

        this.lastSelection = index;
    }

    openDetail(id: any) {
        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('invisible');
        this.$state.go('spotDetail', { id }).then(() => {
            // Apply the animation ONLY when this transition happens
            this.$timeout(() => {
                angular
                    .element(document.querySelector('.sidebar') as HTMLElement)
                    .removeClass('invisible');
                angular
                    .element(document.querySelector('.detail-list-area .side-grid') as HTMLElement)
                    .addClass('slide-left-transition');
            }, 0);
        });
    }

    archiveSpots($event: any) {
        var toArchive = false;
        for (let i = 0; i < this.selectedSpots.length; i++) {
            if (
                this.dynamicItems.getItemAtIndex(this.selectedSpots[i]).archived ===
                false ||
                (this.spots &&
                    this.spots[this.selectedSpots[i]] &&
                    this.spots[this.selectedSpots[i]].archived === false)
            ) {
                toArchive = true;
            }
        }
        this._archiveSpots(this.selectedSpots, toArchive, $event);
    }

    deleteSpots($event: any) {
        this._deleteSpots(this.selectedSpots, $event);
    }

    loadListView() {
        this.displayListView = true;
        this.$cookies.put('comcast.addelivery.spotview', 'list');
    }

    loadGridView() {
        this.displayListView = false;
        this.$cookies.put('comcast.addelivery.spotview', 'grid');
        if (this.newGridLoad) {
            this.searching = true;
            this.newGridLoad = false;
            this.SearchParams = this.$stateParams;
            console.log("the Search param from stateParam " + this.SearchParams)
            var sortField = this.$stateParams.sortField
                ? this.$stateParams.sortField
                : 'date_created';
            var sortDirection = this.$stateParams.sortDirection
                ? this.$stateParams.sortDirection
                : 'DESC';

            this.$timeout(() => {
                for (var prop in this.SearchParams) {
                    if (this.SearchParams.hasOwnProperty(prop)) {
                        this.SearchParams[prop] =
                            this.SearchParams[prop] === '' || this.SearchParams[prop] === null
                                ? undefined
                                : this.SearchParams[prop];
                    }
                }

                this.SearchParams.limit = 100;
                this.SearchParams.sortField = sortField;
                this.SearchParams.sortDirection = sortDirection;
                this.sortObj.direction = sortDirection;

                // COM-11612 - this is a hack to get around the fact that title is often entered with a colon in it.
                // The colon messes up the Node parsing of the field, so pass this one like admin does
                var tempParams = angular.copy(this.SearchParams);

                //COM-12102 - Have to copy/format the ISCIs properly in order to search for/create the right ones,
                // as well as retain the current search criteria
                if (this.SearchParams.isci) {
                    console.log("the isci " + this.SearchParams.isci)
                    tempParams.isci = this._formatIsci(tempParams.isci);
                    if (
                        tempParams.isci instanceof Array &&
                        tempParams.isci.length > 1
                    ) {
                        tempParams.isci = tempParams.isci.join('\n');
                    } else {
                        tempParams.isci = tempParams.isci[0];
                    }
                }

                if (this.SearchParams.title) {
                    tempParams.title = 'like:' + this.SearchParams.title;
                }

                if (this.SearchParams.notes) {
                    tempParams.notes = 'like:' + this.SearchParams.notes;
                }

                if (this.SearchParams.SpotsStatus) {
                    if (this.SearchParams.SpotsStatus !== 'RESLATE') {
                        tempParams.SpotsStatus = this.SearchParams.SpotsStatus;
                        delete tempParams.reslateFlag;
                    } else {
                        delete tempParams.SpotsStatus;
                        tempParams.reslateFlag = true;
                    }
                }

                this.SpotResourceFactory.getProviderSpots(tempParams, {}).subscribe(
                    (spots: any) => {
                        this.spots = spots.rows;
                        // Preset all of the statuses with each new page requested (so we don't end up doing them all at the same time
                        for (let i = 0; i < this.spots.length; i++) {
                            this.spots[i].statusBar = this.readStatus(this.spots[i]);
                        }
                        this.numItems = spots.count;
                        this.spots = this.spots.map((data: any) => {
                            if (
                                data.ThumbnailAsset &&
                                data.ThumbnailAsset.AssetContent &&
                                data.ThumbnailAsset.AssetContent.contentUuid
                            ) {
                                this._getThumbnail(
                                    data.isci,
                                    data.ThumbnailAsset.AssetContent.contentUuid
                                );
                            }
                            return data;
                        });
                        this.searching = false;
                        this.disableScroll = true;
                    }
                );
            });
        }
    }

    loadMoreCards() {
        if (this.spots.length < this.numItems) {
            this.searching = true;
            this.SearchParams.offset = this.spots.length;
            this.SearchParams.limit = 50;
            this.SpotResourceFactory.getProviderSpots(this.SearchParams, {}).subscribe(
                (spots: any) => {
                    this.disableScroll = true;
                    for (let i = 0; i < spots.rows.length; i++) {
                        var newSpot = spots.rows[i];
                        newSpot.statusBar = this.readStatus(newSpot);
                        this.spots.push(newSpot);
                        if (
                            newSpot.ThumbnailAsset &&
                            newSpot.ThumbnailAsset.AssetContent &&
                            newSpot.ThumbnailAsset.AssetContent.contentUuid
                        ) {
                            this._getThumbnail(
                                newSpot.isci,
                                newSpot.ThumbnailAsset.AssetContent.contentUuid
                            );
                        }
                    }
                    this.searching = false;
                    this.disableScroll = true;
                }
            );
        }
    }

    exportToCSV() {
        let exportParams = JSON.parse(JSON.stringify(this.dynamicItems.SearchParams));
        if (exportParams.SpotsStatus) {
            let spotStatus = exportParams.SpotsStatus;
            if (spotStatus !== 'RESLATE') { 
                delete exportParams.reslateFlag;
            } else {
                exportParams.SpotsStatus = 'UNSENT';
                exportParams.reslateFlag = true;
            }
        }
        this.SpotResourceFactory.getSpotMetadata(exportParams, {}).subscribe(
            (csv: any) => {
                let blob: any = new Blob([csv.body], {
                    type: 'application/json', //csv.config['Content-Type'],
                });

                if (bowser.msie || bowser.msedge) {
                    this.$window.navigator.msSaveBlob(blob, 'spots.csv');
                } else {
                    var url = URL.createObjectURL(blob);
                    let downloadLink: any = document.createElement('A');
                    downloadLink.href = url;
                    downloadLink.download = 'spots.csv';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
                URL.revokeObjectURL(blob);
            }
        );
    }

    isSelected(rolesToValidate: any) {
        return (
            this.selectedSpots.length < 1 ||
            !this.loginService.hasPermission(rolesToValidate.split(','))
        );
    }

    readStatus(spot: any) {
        if (spot === undefined) {
            return;
        }

        var session = this.loginService.getSessionData();
        this.activeUploads = session.activeUploads ? session.activeUploads : [];
        for (let i = 0; i < this.activeUploads.length; i++) {
            if (this.activeUploads[i].spotId === spot.id) {
                spot.status = 'UPLOADING';
            }
        }

        return this.StatusService.readSpotStatus(spot);
    }

    importSpots() {
        var fileInput = angular.element(
            document.querySelector('#importSpotFileSelector') as HTMLElement
        );

        // Using .one() allows us to not have to call .off() beforehand every time
        fileInput.one('change', (e: any) => {
            let uploadedFile = e.target.files[0];

            this.importingSpots = true;

            // Need to get a this reference for inside the named headers() method
            let vm = this;

            // Create a new Flow object that will be used to upload the file
            this.fileToUpload = new Flow({
                target: this.EndPointService.spotImportEndPoint,
                headers() {
                    // You have to use the `vm` reference in here, because `this` refers to the headers() function here
                    let accountHash = JSON.parse(
                        vm.$window.localStorage[vm.authConstants.session.ACCOUNT_HASH]
                    );
                    let windowSessionData = JSON.parse(
                        vm.$window.sessionStorage[vm.authConstants.session.SESSION_DATA]
                    );

                    return {
                        Authorization:
                            'Bearer ' + accountHash[windowSessionData.accountId],
                    };
                },
                uploadMethod: 'POST',
                withCredentials: true,
                allowDuplicateUploads: true,
                // 100Mb chunks, should force everything to go at once, since it's limited to that max size
                // It's important to send this all at once since we're going straight to RE
                chunkSize: 100 * 1024 * 1024,
                query: {},
                testChunks: false, // This allows flow.js to make test calls to see which chunks have already been uploaded. So it can resume where it left off.
            });

            // Add the spot file to the uploader
            this.fileToUpload.addFile(uploadedFile);

            // Handle upload events
            this.fileToUpload.off();
            // Once the upload completes, submit the metadata
            this.fileToUpload.on('fileError', (file: any, message: any) => {
                this.importingSpots = false;
                fileInput.val('');
                var formattedMessage =
                    '<p>' +
                    message
                        .substring(1, message.length - 1)
                        .split(';')
                        .join('<br/>') +
                    '</p>';

                var error = this.$mdDialog
                    .alert()
                    .title('The server was unable to process your file')
                    .htmlContent(formattedMessage)
                    .ariaLabel('The server was unable to process your file')
                    .ok('OK');

                this.$mdDialog.show(error);
            });

            this.fileToUpload.on('fileSuccess', () => {
                this.importingSpots = false;
                fileInput.val('');
                this.NotificationService.hideNotificationToast();
                this.NotificationService.showNotificationToast(
                    'Successfully imported spots'
                );
                this.$state.reload();
            });

            // Begin the upload
            this.fileToUpload.upload();
            this.NotificationService.showNotificationToast('Spot Import Started');
            fileInput.val('');
        });

        // Proxy the click to the real file selector because Material Design doesn't have file selectors
        fileInput[0].click();
    }

    sendToProdSvcs($event: any) {
        this._sendSpotsToProdSvcs(this.selectedSpots, $event);
    }

    bulkUpload() {

            let User =localStorage.getItem("lastLoggedInUserName")
            this.GoogleAnalyticsServiceService.logCustomEvent("Bulk Upload Button","Clicked"," By User :"+User)   
      
        // Also let that component handle the metadata updates/input
        this.BulkUploadService.openUploadDialog();
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    private _getThumbnail(isci: any, uuid: any) {
        this.AssetResourceFactory.getImageAsset(
            { uuid },
            {},
            (asset: any) => {
                var blob = new Blob([asset.data], {
                    type: asset.config['Content-Type'],
                });
                this.spotThumbs[isci] = URL.createObjectURL(blob);
            },
            () => {
                // Unable to retrieve the image asset for the thumbnail
            }
        );
    };

    private _viewMetadataReport(spotId:string) {
        if (this.downloadUuids.qcReport === false) {
            // If we have no metadata report, go ahead and just peace-out
            return;
        }
        // Doing this here only because the resolve function needs a way to reference the outer scope
        let vm = this;
        return this.$mdDialog.show({
            controller: 'MetadataReportController',
            controllerAs: 'vm',
            template: require('../view/metadataReportDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // For small screens only
            resolve: {
                reportData(): any {
                    return {assetContentId: vm.downloadUuids.qcReport, spotId: spotId};
                },
            },
        });
    };

    private _viewHistoryReport(spot: any) {
        // Make request to get __sessionKey
        this.AuthenticationResourceFactory.getSessionKey(
            {},
            (sessionKey: any) => {
                // Append __sessionKey to request to Node (instead of RE)

                this.$window.open(
                    this.EndPointService.assetDownloadReportEndpoint +
                    '/' +
                    spot.id +
                    '/' +
                    spot.isci +
                    '?sessionKey=' +
                    sessionKey.data+
                    '&' +
                    'authorization=' +
                    this.loginService.getJwt(),
                    '_blank'
                );

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    };

    private _archiveSpots(spots: any, flag: any, ev: any) {
        // Only run the command on actionable spots
        var filteredSpots = spots.filter((cur: any) => {
            if (this.spotView !== 'grid') {
                return this.dynamicItems.getItemAtIndex(cur).archived !== flag;
            } else {
                return this.spots[cur].archived !== flag;
            }
        });
        var confirmOrNot;

        if (flag) {
            var confirm = this.$mdDialog
                .confirm()
                .title('Would you like to continue?')
                .textContent(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .ariaLabel(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .targetEvent(ev)
                .ok('Continue')
                .cancel('Cancel');

            confirmOrNot = this.$mdDialog.show(confirm);
        } else {
            confirmOrNot = this.$q.when(true);
        }

        confirmOrNot.then(
            () => {
                // User confirmed the archive function
                // Set the flag to show whether or not there are any archived spots selected (since we're making them all the same with this function)
                this.onlyArchivesSelected = flag;
                filteredSpots = filteredSpots.map((cur: any) => {
                    //Have to archive the spots on both views
                    var spot = this.dynamicItems.getItemAtIndex(cur);
                    if (this.spotView === 'grid') {
                        var gridSpot = this.spots[cur];
                        gridSpot.archived = flag;
                    }
                    spot.archived = flag;
                    return spot;
                });

                this.$q.all(
                    filteredSpots.map((cur: any) => {
                        return this.SpotResourceFactory.save({ id: cur.id }, cur);
                    })
                ).then(
                    () => {
                        // Spots successfully archived
                    },
                    () => {
                        // Spots Failed to be Archived
                    }
                );
            },
            function () {
                // User cancelled the archive function
            }
        );
    };

    private _deleteSpots(spots: any, ev: any) {
        var confirmOrNot;

        var confirm = this.$mdDialog
            .confirm()
            .title('Would you like to continue?')
            .textContent('Are you sure you wish to delete the selected spot(s)?')
            .ariaLabel('Are you sure you wish to delete the selected spot(s)?')
            .targetEvent(ev)
            .ok('Continue')
            .cancel('Cancel');

        confirmOrNot = this.$mdDialog.show(confirm);

        confirmOrNot.then(
            () => {
                // User confirmed the delete function
                this.$q.all(
                    spots.map((cur: any) => {
                        return this._deleteSpot(this.dynamicItems.getItemAtIndex(cur));
                    })
                ).then(
                    () => {
                        let msg =
                            this.spotDeletedCount > 0
                                ? this.spotDeletedCount + ' spot(s) were deleted.  '
                                : '';
                        msg +=
                            this.spotNotDeletedCount > 0
                                ? this.spotNotDeletedCount +
                                ' spot(s) were UNABLE to be deleted.  For example, you cannot delete spots that are already in an order.'
                                : '';
                        if (msg) {
                            this.NotificationService.showNotificationToast(msg);
                        }
                        this.spotDeletedCount = 0;
                        this.spotNotDeletedCount = 0;
                        this.$state.reload();
                    },
                    (failure: any) => {
                        //spot failed to be deleted
                        this.NotificationService.showNotificationToast(
                            'Error deleting spots',
                            failure.data
                        );
                    }
                );
            },
            function () {
                // User cancelled the delete function
            }
        );
    };

    private _deleteSpot(spot: any) {
        let deletePromise = this.$q.defer();

        this.SpotResourceFactory.delete({ id: spot.id }).subscribe(
            () => {
                //individual deletion succeeded
                this.spotDeletedCount++;
                deletePromise.resolve('true');
            },
            (failure: any) => {
                //individual deletion failure
                if (failure.status === 304) {
                    this.spotNotDeletedCount++;
                    deletePromise.resolve('true');
                } else {
                    deletePromise.resolve('true');
                }
            }
        );

        return deletePromise.promise;
    }

    private _sendSpotsToProdSvcs(spots: any, ev: any) {
        let confirmOrNot;

        let confirm = this.$mdDialog
            .confirm()
            .title('Would you like to continue?')
            .textContent(
                'Are you sure you wish to send the selected spot(s) to Prod Services?'
            )
            .ariaLabel(
                'Are you sure you wish to send the selected spot(s) to Prod Services?'
            )
            .targetEvent(ev)
            .ok('Continue')
            .cancel('Cancel');

        confirmOrNot = this.$mdDialog.show(confirm);

        confirmOrNot.then(
            () => {
                // User confirmed the sent function
                this.$q.all(
                    spots.map((cur: any) => {
                        return this._sendSpotToProdSvcs(this.dynamicItems.getItemAtIndex(cur));
                    })
                ).then(
                    () => {
                        let sentVerbTense =
                            this.spotSentCount === 1 ? ' was' : '(s) were';
                        let unsentVerbTense =
                            this.spotNotSentCount === 1 ? ' was' : '(s) were';
                        let msg =
                            this.spotSentCount > 0
                                ? this.spotSentCount +
                                ' spot' +
                                sentVerbTense +
                                ' sent to Prod Services.  '
                                : '';
                        msg +=
                            this.spotNotSentCount > 0
                                ? this.spotNotSentCount +
                                ' spot' +
                                unsentVerbTense +
                                ' UNABLE to be sent to Prod Services.'
                                : '';
                        if (msg) {
                            this.NotificationService.showNotificationToast(msg);
                        }
                        this.spotSentCount = 0;
                        this.spotNotSentCount = 0;
                        this.$state.reload();
                    },
                    (failure: any) => {
                        //spot failed to be sent
                        this.NotificationService.showNotificationToast(
                            'Error sending spots to Prod Services',
                            failure.data
                        );
                    }
                );
            },
            function () {
                // User cancelled the send function
            }
        );
    }

    private _sendSpotToProdSvcs(spot: any) {
        var sentPromise = this.$q.defer();

        if (
            (spot.status === 'PENDING_MEDIA' || spot.status === 'UNSENT') &&
            (spot.SDSpotLink === null || spot.SDSpotLink.linkedFlag === false) &&
            this.hasProdVendor
        ) {
            this.SpotResourceFactory.sendToProdSvcs(
                { id: spot.id },
                {},
            ).subscribe(
                () => {
                    this.spotSentCount++;
                    sentPromise.resolve('true');
                },
                (err: any) => {
                    console.log(err);
                    this.spotNotSentCount++;
                    sentPromise.resolve('true');
                }
            );
        } else {
            this.spotNotSentCount++;
            sentPromise.resolve('true');
        }

        return sentPromise.promise;
    }

    private _formatIsci(isci: any) {
        var parsedIsci = isci.trim().split(/\n+|,+|;+/);
        if (parsedIsci instanceof Array && parsedIsci.length > 1) {
            var validIsciArray = [];
            //remove any that ended up empty due to extra characters, and trim
            for (let i = 0; i < parsedIsci.length; i++) {
                if (parsedIsci[i].trim().length > 0) {
                    validIsciArray.push(
                        parsedIsci[i].trim().replace(/\W/g, '').toUpperCase()
                    );
                }
            }
            return validIsciArray;
        } else {
            return [isci.replace(/\W/g, '')];
        }
    }
}
